import React, { useState, useEffect, useRef } from 'react';

import { applyStyle, getStyleChar } from './StyleControlsUtils';

import s from './SizeStyleControl.module.scss';
import { FontSizeStyleMap } from './StyleMaps';
import { FontFamilies } from './FontFamilies';
import NumberInput from '../../Components/Common/Inputs/Number/Number';
import FontManager from '../../utils/FontManager';




const SizeStyleControls = (props) => {
  const [sizeValue, setSizeValue] = useState(undefined);
  const [lineValue, setLineValue] = useState(props.block.lineHeight.value);
  const [weightValue, setWeightValue] = useState(undefined);

  useEffect(() => {
    const newSizeValue = getValue(props.editorState, 'size');
    setSizeValue(newSizeValue);

    const newWeightValue = getValue(props.editorState, 'weight');
    setWeightValue(newWeightValue);

  }, [props.editorState]);

  const getValueFromChar = (char, type) => {
    if (!char) {
      return 0;
    }
    const arr = char.getStyle().toArray();
    let value = '';
    arr.forEach((style) => {
      if (style.search(type + '-') === 0) {
        value = style.substring(type.length+1);
      }
    });
    return value;
  };

  const getValue = (editorState, type) => {
    let char = getStyleChar(editorState);
    return getValueFromChar(char, type);
  };

  const onChange = (value, type) => {
    const style = type + '-' + value;
    const oldStyle = type + '-' + getValue(props.editorState, type);
    let nextEditorState = applyStyle(props.block, props.editorState, style, { ...FontSizeStyleMap(), [oldStyle]: {} });
    props.onChange(nextEditorState);

  };


  const onSizeChange = (v) => {
    const value = `${v}px`;
    onChange(value, 'size');
  };

  const onLineChange = (v) => {
    const value = Math.round(parseFloat(v)*10)/10.0;
    props.block.lineHeight.value = value;
    setLineValue(value);

    props.block.view.update();
  }

  const onWeightChange = (v) => {
    let fontName = getValue(props.editorState, 'font');
    if(!fontName){
      fontName = 'Quicksand' //set default font name;
    }
    const previousValue = processValue(getValue(props.editorState, 'weight'), 'weight');

    // console.log('data:', fontName, previousValue);

    let direction = -1; //default is negative direction of weight increase 
    if(previousValue < v){
      direction = 1; //positive direction of weight increase
    }
    else if(previousValue == v){
      direction = 0;
    }

    let adjustedValue = v;
    //get list of all supported weigts 
    const fontDesciption = FontFamilies.find( (font)=>{return font.value === fontName;} )
    if(fontDesciption && fontDesciption.weights){
      //get closest weight that we have in list;
      const weights = fontDesciption.weights;
      var closest = weights.reduce(function(prev, curr) {
        return (Math.abs(curr - previousValue) < Math.abs(prev - previousValue) ? curr : prev);
      });

      let weightIndex = weights.findIndex( (v) => v==closest );
      weightIndex += direction;

      weightIndex = Math.min(Math.max(weightIndex, 0), weights.length-1);
      adjustedValue = weights[ weightIndex ];
    }

    FontManager.getInstance().loadFont(fontName, adjustedValue);
    onChange(adjustedValue, 'weight');
  }


  const processValue = (value, type) => {

    const n = parseInt(value);
    if (isNaN(n)) {
      switch (type) {
        case 'size':
          return 25;
        case 'weight':
          return 400;
      }
    }
    else {
      return n;
    }
  }

  return (
    <div className={s.container}>

      <NumberInput
        min={1}
        max={200}
        step={1}
        value={processValue(sizeValue, 'size')}
        onChange={onSizeChange}
        tooltip='Font Size'
      />

      <div className={s.spacer}></div>

      <NumberInput
        min={0.1}
        max={2.5}
        step={0.1}
        value={lineValue}
        onChange={onLineChange}
        tooltip='Line Height'
      />

      <div className={s.spacer}></div>

      <NumberInput
        min={100}
        max={900}
        step={100}
        value={processValue(weightValue, 'weight')}
        onChange={onWeightChange}
        tooltip='Font Weight'
      />

    </div>
  );
};

export default SizeStyleControls;
