import React, { useState, useRef, useEffect } from 'react';
import s from './Notification.module.scss';

type NotificationProps = {
    children : JSX.Element
}

function Notification( props: NotificationProps ){

    return (
        <div className={s.container}>
            {props.children}
        </div>
    )
}

export default Notification;