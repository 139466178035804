import React from 'react';
import styled from 'styled-components';

const StyleButtonStyle = styled.div`
  position: relative;
  background: ${(props) => (props.active ? '#000' : 'white')};
  //border: #cbcbcb 1px solid;
  border-radius: 4px;
  margin-right: 5px;
  height: 30px;
  width: 30px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => (props.active ? '#fff' : '#000')};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    // width: 14px;
    // height: 14px;
    > path{
      fill: ${(props) => (props.active ? '#fff' : '#000')};
    }
  }

  &:hover{
    .tooltip {
      display: block;
    }
  }
`;

const Tooltip = styled.span`
  position: absolute;
  top: 35px;
  display: none;
  color: #fff;
  background-color: #000;
  font-size: 12px;
  border-radius: 5px;
  z-index: 2;
  padding: 0 5px 0 5px;
  // width: 100px;
  text-align: center;
  height: 20px;
  white-space: nowrap;
  font-weight: normal;
`

export default class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      if (this.props.onToggle) {
        e.preventDefault();
        this.props.onToggle(this.props.style);
      }
    };
  }

  render() {
    return (
      <StyleButtonStyle active={this.props.active} onMouseDown={this.onToggle}>
        {this.props.icon}
        {this.props.tooltip ? <Tooltip className='tooltip'>{this.props.tooltip}</Tooltip> : ''}
        
      </StyleButtonStyle>
    );
  }
}
